<template>
    <div class="admin-wrapper" :style="{ backgroundImage: `url('${bgImage}')` }">
        <div class="control-panel-wrapper">
            <div class="control-panel">
                <a class="admin-button" :class="{ 'admin-button--disabled': ! currentQuestion }" @click="showAnswer">Antwoord</a>
                <a class="admin-button" @click="resetGame">Reset</a>
                <a class="admin-button" :class="{ 'admin-button--disabled': ! currentQuestion }" @click="startGame">Start</a>

                <div class="form-field">
                    <label class="label" for="time">Tijd</label>
                    <input
                        type='number'
                        class="time-input"
                        v-model='settings.time'
                        @input="changeSetting('time')"
                    >
                </div>

                <v-select :options="questions" v-model="currentQuestion" @option:selected="handleLocationChange" />

                <div class="form-field-range">
                    <label>Diameter kaas</label>
                    <div class="range-wrapper">
                        <input
                            type="range"
                            min="1"
                            max="50"
                            step="0.1"
                            v-model="settings.cheeseSize"
                            @input="changeSetting('cheeseSize')"
                        >
                        <div>{{ settings.cheeseSize }}</div>
                    </div>
                </div>

                <div class="form-field-range">
                    <label>Diameter antwoord</label>
                    <div class="range-wrapper">
                        <input
                            type="range"
                            min="1"
                            max="50"
                            step="0.1"
                            v-model="settings.answerSize"
                            @input="changeSetting('cheeseSize')"
                        >
                        <div>{{ settings.answerSize }}</div>
                    </div>
                </div>
                <div class="form-field">
                    <label @click="toggleTestMode">Test mode</label>
                    <toggle-switch v-model="testMode" @input="setTestMode" />
                </div>
            </div>
        </div>
    <div class="game-wrapper">
        <game-component admin />
    </div>
    </div>
</template>

<style lang="scss" scoped>

    .admin-wrapper {
        width: 100vw;
        height: 100vh;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        padding: 50px;
        box-sizing: border-box;
        display: flex;
    }

    .game-wrapper {
        padding: 0 30px;
        box-sizing: border-box;
        width: calc(100vh - 100px);
        height: calc(100vh - 100px);
    }

    .control-panel {
        background: #ffffff;
        border-radius: 3px;
        width: 250px;
        padding: 20px;
        box-sizing: border-box;

        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .admin-button {
        display: block;
        text-decoration: none;
        padding: 10px;
        text-align: center;
        font-size: 1.25em;

        &:not(&--disabled) {
            background: #00b8ff;
            color: #ffffff;
            cursor: pointer;
        }
        &--disabled {
            background: #cccccc;
            color: #444444;
        }
    }

    .form-field {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .label {
            width: 100px;
        }
    }

    .form-field-range {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .range-wrapper {
            display: flex;
            gap: 10px;

            input {
                flex: 1;
            }
        }
    }

    .time-input {
        padding: 10px;
        width: 50px;
    }
</style>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import _ from 'lodash';
import gameComponent from "./game";
import axios from "axios";
import ToggleSwitch from "@/components/ToggleSwitch";

export default {
    name: 'adminComponent',
    components: {ToggleSwitch, vSelect, gameComponent },

    data() {
        return {
            bgImage        : require("../assets/images/background_blur.jpg"),
            currentQuestion: null,
            questions      : [],
            testMode       : false,
            settings       : {
                cheeseSize : 3,
                answerSize : 3,
                time       : 15,
            },
                changeSetting   : _.debounce(this.doChangeSetting, 100)
            };
        },

    sockets: {
        connect: function () {
            console.log('socket connected')
        },
        customEmit: function () {
            console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
        }
    },

    created() {
        this.sockets.subscribe('settings', (data) => {
            this.setSettings(data);
        });

        this.sockets.subscribe('set_test_mode', (data) => {
            this.testMode = data;
        });
    },

    async mounted() {
        const response = await axios.get('/questions.json');
        this.questions = response.data;

        setTimeout(() => {
            this.$socket.emit('get_settings');
        }, 2000);
    },

    methods: {
        showAnswer: function () {
            this.$socket.emit('show_answer', this.currentQuestion);
            this.$socket.emit('set_test_mode', false);
            setTimeout(() => {
                this.currentQuestion = null;
            }, 2000);
        },
        startGame: function () {
            if (! this.currentQuestion.label) return;
            this.$socket.emit('start_game', {time: this.settings.time});
        },
        resetGame: function () {
            // $socket is socket.io-client instance
            this.$socket.emit('reset_game');
            this.setTestMode(false);
        },
        doChangeSetting() {
          this.settings.time = Number(this.settings.time);
          this.$socket.emit('set_settings', this.settings)
        },
        setSettings(data) {
            console.log(data)
            this.settings.cheeseSize = data.cheeseSize;
            this.settings.answerSize = data.answerSize;
            this.testMode            = data.testMode;
            this.settings.time       = Number(data.time);
        },
        handleLocationChange(event) {
            if (event === null) return;
            this.$socket.emit('hide_answer');

            setTimeout(() => {
                this.$socket.emit('set_answer_location', this.currentQuestion);
            }, 100)
        },
        toggleTestMode() {
            this.testMode = ! this.testMode;
            this.$socket.emit('set_test_mode', this.testMode);
        },
        setTestMode(value) {
            this.testMode = value;
            this.$socket.emit('set_test_mode', this.testMode);
        }
    }
}
</script>
