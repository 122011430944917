<template>
    <div class="game" :style="{ backgroundImage: `url('${map}')` }">
        <div ref="timer" class="timer" :style="{ backgroundImage: `url('${timerImage}')` }">{{ timer }}</div>
        <div
            class='kaas'
            ref="kaas"
            :style="{
                backgroundImage: `url('${kaasImage}')`,
                top: `${cheeseTopPos}%`,
                left: `${cheeseLeftPos}%`,
                transform: `translate(-50%, -50%) rotate(${this.rotation}deg)`
            }"
        />
        <div
            v-show="showAnswer || admin"
            class='answer'
            :class="{
                'answer-admin': admin,
                'answer-hidden': ! showAnswer,
            }"
            ref="answer"
            :style="{
                top: `${answerLocation.top}%`,
                left: `${answerLocation.left}%`,
            }"
        />
    </div>
</template>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

.game {
    position: relative;
    width: 100%;
    height: 100%;
    background: {
        size: contain;
        position: center center;
        repeat: no-repeat;
    }
}

.kaas {
    display: block;
    position: absolute;
    border-radius: 50%;
    transition-duration: .5s;
    transition-timing-function: linear;
    background: {
        size: contain;
        position: center center;
        repeat: no-repeat;
    }
}

.answer {
    display: block;
    position: absolute;
    border-radius: 50%;
    background: rgba(200, 0, 0, 0.75);
    transform: translate(-50%, -50%);

    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
}

.answer-admin.answer-hidden {
    border: 4px solid rgba(200, 0, 0, 0.75);
    box-sizing: border-box;
    background: none !important;
}

.timer {
    height: 18%;
    background-size: contain;
    background-repeat: no-repeat;
    text-align: center;
    font-family: 'Great Vibes', cursive;
    font-size: 200%;
    color: #263E93;
    box-sizing: border-box;
}
</style>

<script>

import _ from 'lodash';

export default {
    name: 'gameComponent',

    props: {
        admin: { type: Boolean, default: false }
    },

    data() {
        return {
            map: require("../assets/images/nederland.png"),
            kaasImage: require("../assets/images/kaas.png"),
            bgImage: require("../assets/images/background_blur.jpg"),
            timerImage: require("../assets/images/tegeltje.png"),
            showAnswer: false,
            timer: 0,
            rotation: 0,
            timerHasStarted: false,
            position: {top: 50, left: 50},
            answerLocation: {top: 50, left: 50},
            testMode: false,
            settings: {
                cheeseSize: 3,
                answerSize: 4
            }
        };
    },

    computed: {
        cheeseTopPos() {
            return this.position.top;
            // return this.position.top - (this.settings.cheeseSize / 2)
        },
        cheeseLeftPos() {
            return this.position.left;
            // return this.position.left - (this.settings.cheeseSize / 2)
        }
    },

    created: function () {

        this.sockets.subscribe('navigate_cheese', (data) => {
            this.navigate(data);
        });
        this.sockets.subscribe('show_answer', () => {
            this.showAnswer = true;
        });
        this.sockets.subscribe('set_answer_location', (data) => {
            this.setAnswerLocation(data);
        });
        this.sockets.subscribe('hide_answer', () => {
            this.hideAnswer();
        });
        this.sockets.subscribe('start', (data) => {
            this.startGame(data);
        });
        this.sockets.subscribe('reset', (data) => {
            this.reset(data);
        });
        this.sockets.subscribe('settings', (data) => {
            this.setSettings(data);
        });
        this.sockets.subscribe('set_test_mode', (data) => {
            this.testMode = data;
        });
    },

    mounted() {
        this.$socket.emit('get_settings');
        this.setTimerSize();
        window.addEventListener("resize", _.debounce(this.setTimerSize, 100));
    },

    sockets: {
        connect: function () {
            console.log('socket connected')
        },
    },

    methods: {
        navigate(data) {
            if (this.timer > 0 || this.testMode) {
                this.position.top = data.top;
                this.position.left = data.left;
                this.rotation += 4;
            }
        },

        reset(data) {
            this.position.top  = data.top;
            this.position.left = data.left;
            this.timer         = 0;
            this.showAnswer    = false;
        },

        setAnswerLocation(location) {
            this.showAnswer = false;
            this.answerLocation.top = location.top;
            this.answerLocation.left = location.left;
        },

        hideAnswer() {
            this.showAnswer = false;
        },

        startGame(data) {
            this.showAnswer = false;
            if (!this.timerHasStarted) {
                this.$socket.emit('set_test_mode', false);
                this.testMode = false;
                this.timer = data.time;
                this.startTimer();
            }
        },

        startTimer() {
            this.timerHasStarted = true;

            const interval = setInterval(() => {
                this.timer--;

                if (this.timer <= 0) {
                    clearInterval(interval);
                    this.timerHasStarted = false;

                }
            }, 1000)
        },

        setSettings(data) {
            const kaas = this.$refs.kaas;
            const answer = this.$refs.answer;
            kaas.style.width = data.cheeseSize + "%";
            kaas.style.height = data.cheeseSize + "%";
            answer.style.width = data.answerSize + "%";
            this.position.top = data.pos.top;
            this.position.left = data.pos.left;
            this.timer = data.timer;
            this.testMode = data.testMode;

            this.settings.cheeseSize = data.cheeseSize;
            this.settings.answerSize = data.answerSize;

            if (this.timer > 0) {
                this.startTimer();
            }
        },

        setTimerSize() {
            const timer = this.$refs.timer;
            const height = timer.offsetHeight;
            timer.style.width = height + "px";
            timer.style.lineHeight = height + "px";
            timer.style.fontSize = height / 2.5 + "px";
        }
    }
}
</script>
