<template>
  <div class="remote-wrapper" :style="{ backgroundImage: `url('${bgImage}')` }">
    <div class="remote-buttons__wrapper">
      <div class="remote-buttons">
        <img class="remote-button__up" src="../assets/images/up.png" @click="sendMessage('up')">
        <img class="remote-button__down" src="../assets/images/down.png" @click="sendMessage('down')">
        <img class="remote-button__left" src="../assets/images/left.png" @click="sendMessage('left')">
        <img class="remote-button__right" src="../assets/images/right.png" @click="sendMessage('right')">
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  html, body {
    overscroll-behavior: none;
  }

  .remote-wrapper {
    min-height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  .remote-buttons__wrapper {
    width: 100%;
    max-width: 90vh;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }

  .remote-buttons {
    display: grid;
    grid-gap: 2%;
    width: 90%;
    margin: 5%;
    grid-template-rows: 1fr 1fr;

    img {
      width: 100%;
      border: 5px solid #fff;
      box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.4);
      box-sizing: border-box;
    }
    .remote-button__up {
      grid-column: 2;
      grid-row: 1;
    }
    .remote-button__down {
      grid-column: 2;
      grid-row: 2;
    }
    .remote-button__left {
      grid-column: 1;
      grid-row: 2;
    }
    .remote-button__right {
      grid-column: 3;
      grid-row: 2;
    }
  }

</style>

<script>
export default {
    name: 'remoteComponent',

    data() {
        return {
            connection: null,
            bgImage: require("../assets/images/remote_background.jpg"),
            disableNavigation: false,
        };
    },

    created: function() {
        this.sockets.subscribe('timer_stopped', () => {
            this.disableNavigation = true;
            navigator.vibrate(1500);
            setTimeout(() => {
                this.disableNavigation = false;
            }, 2000);
        });
    },

    mounted() {
        document.addEventListener('contextmenu', event => event.preventDefault());
    },

    sockets: {
        connect: function () {
            console.log('socket connected')
        },
        customEmit: function () {
            console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
        }
    },
    methods: {
        sendMessage: function (data) {
            if (this.disableNavigation) return;

            navigator.vibrate(25);
            // $socket is socket.io-client instance
            this.$socket.emit('navigate', data)
        }
    }
}
</script>
