var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"game",style:({ backgroundImage: `url('${_vm.map}')` })},[_c('div',{ref:"timer",staticClass:"timer",style:({ backgroundImage: `url('${_vm.timerImage}')` })},[_vm._v(_vm._s(_vm.timer))]),_c('div',{ref:"kaas",staticClass:"kaas",style:({
            backgroundImage: `url('${_vm.kaasImage}')`,
            top: `${_vm.cheeseTopPos}%`,
            left: `${_vm.cheeseLeftPos}%`,
            transform: `translate(-50%, -50%) rotate(${this.rotation}deg)`
        })}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAnswer || _vm.admin),expression:"showAnswer || admin"}],ref:"answer",staticClass:"answer",class:{
            'answer-admin': _vm.admin,
            'answer-hidden': ! _vm.showAnswer,
        },style:({
            top: `${_vm.answerLocation.top}%`,
            left: `${_vm.answerLocation.left}%`,
        })})])
}
var staticRenderFns = []

export { render, staticRenderFns }